<template>
  <div class="notsupported" v-if="!isProcessing">
    <div class="notsupported__item">
      <v-icon class="notsupported__item__icon">sentiment_very_dissatisfied</v-icon>
      <h1 class="notsupported__item__title">サポート対象外のブラウザです</h1>
      <p class="notsupported__item__explain">
        お手数をおかけして大変申し訳ありませんが、動作サポート対象を行っているSafariまたはChromeを使って、このページを開いてお使いください。<br><br>
        以下のボタンをタップすると、このページのURLをコピーすることができます。
      </p>
      <v-btn class="notsupported__item__btn" outlined @click="copyReferrer()">表示先のURLをコピーする</v-btn>
      <p class="notsupported__item__company">
        {{ serviceName }}<br>
        運営：<a class="notsupported__item__company__url"
              :href="companyURL" target="_blank" rel="noopener noreferrer">{{ companyName }}</a>
      </p>
    </div>
  </div>
</template>

<script>
// コピーするリファラのベース
const REFERRER_BASE_URL = window.location.protocol + '//' + window.location.host

export default {
  name: 'notsupported',
  async mounted () {
    // 設定の基本情報が存在しない場合は取得
    const promises = []
    const settingKey = ['companyName', 'companyURL', 'serviceName', 'contact']
    for (const key of settingKey) {
      if (!this[key]) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', key))
    }

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    },
    /**
     * @return {String} 運営の名前
     */
    companyName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyName')
      return data ? data.value : null
    },
    /**
     * @return {String} 運営元のURL
     */
    companyURL () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyURL')
      return data ? data.value : null
    },
    /**
     * @return {String} 遷移元のページURL(取得できなかったときはTopページ)
     */
    referrer () {
      return REFERRER_BASE_URL + (this.$store.getters.referrerPath ? this.$store.getters.referrerPath : '/')
    }
  },
  methods: {
    /**
     * 遷移元のページURLをクリップボードにコピーする
     */
    copyReferrer () {
      this.$copyText(this.referrer).then(
        () => {
          // コピーしたらテロップを表示
          const msg = '表示先のURLをコピーしました'
          this.$store.commit('setTelop', { show: true, msg: msg, type: 'success' })
        },
        () => {
          // コピーできなかったらエラーテロップを表示
          const msg = '表示先のURLをコピーできませんでした'
          this.$store.commit('setTelop', { show: true, msg: msg, type: 'error' })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";
@import "@/assets/sass/fontfamily.scss";

.notsupported {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  padding: $padding_width $padding_width;
  text-align: center;
  background-color: $gray_lighten_color;
  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 $unit_size * 4;
    transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__icon {
      font-family: $material-outlined;
      font-size: 12rem;
      &.theme--light.v-icon {
        color: $black_color;
      }
    }
    &__title {
      margin-top: $unit_size;
      font-size: 1.8rem;
      color: $black_color;
    }
    &__explain {
      margin: $unit_size * 3 0 0;
      font-size: 1.4rem;
      color: $black_lighten_color;
      text-align: left;
    }
    &__btn {
      margin-top: $unit_size * 3;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2rem;
      border-radius: 15px;
      box-shadow: none;
      &.v-btn:not(.v-btn--round) {
        min-width: auto;
        height: auto;
        padding: $unit_size $unit_size * 3;
      }
      &.theme--light.v-btn:not(.v-btn--flat) {
        color: $white_color !important;
        background-color: $concept_color;
      }
    }
    &__company {
      margin: $unit_size * 3 0 0;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $black_lighten_color;
    }
  }
}
</style>
